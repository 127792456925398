<template>
    <div class="mcontainer height-screen">
        <bread-crumb :items="breadcrumbs" />

        <div class="card p-3 mt-4 max-w-670 overflow-visible">
            <loading :loading="loading" spinner-class="justify-center">
                <div class="w-full mt-2">
                    <form @submit.prevent="submit()">
                        <div class="p-7 space-y-5">
                            <div>
                                <label class="font-semibold">Judul Pembahasan</label><br>
                                <input class="form-control" v-model="form.data.title" type="text" value="" autocomplete="off" required>
                            </div>

                            <div class="form-group">
                                <label class="font-semibold">File</label><br>
                                <input-file :placeholder="form.data.filePlaceholder ? form.data.filePlaceholder : ''" @change="(val) => form.data.file = val" />
                            </div>

                            <div class="form-group">
                                <label class="font-semibold">Link</label><br>
                                <textarea name="link" class="form-control" v-model="form.data.link" cols="30" rows="5"></textarea>
                            </div>

                            <div>
                                <div v-for="i in data.mentors" :key="i.id">
                                    <label class="font-semibold">{{ i.fullname }}</label>
                                    <b-row>
                                        <b-col>
                                            <datepicker
                                                @selected="(val) => onDateSelected(i.id, val)"
                                                :bootstrap-styling="true"
                                                placeholder="Pilih tanggal"
                                                class="custom-input"
                                                :disabled-dates="disabledDates"
                                            />
                                        </b-col>
                                        <b-col>
                                            <input
                                                type="time"
                                                @change="(e) => onTimeSelected(i.id, e)"
                                                placeholder="Pilih waktu"
                                            >
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </div>
                        <!-- form footer -->
                        <div class="border-t flex justify-center border-gray-100 p-6">
                            <button class="lg:w-2/3 h-9 lg:px-5 rounded-md bg-blue-600 text-white space-x-1.5 px-5">
                                <span v-if="form.loading"><b-spinner small label="Loading..." /></span>
                                Ajukan Sekarang
                            </button>
                        </div>
                    </form>
                </div>
            </loading>
        </div>
    </div>
</template>

<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import Loading from '@/components/Loading'
    import Datepicker from 'vuejs-datepicker'
    import { mapState, mapActions } from 'vuex'
    import dayjs from 'dayjs'
    import InputFile from '@/components/InputFile.vue'

    export default {
        components: {
            BreadCrumb,
            Loading,
            Datepicker,
            InputFile
        },
        computed: {
            ...mapState('skripsi', { data: 'data', loading: 'loading' }),
            disabledDates () {
                return {
                    to: dayjs().subtract(1, 'day').toDate()
                }
            }
        },
        data () {
            return {
                breadcrumbs: [
                    {
                        route: 'PengajuanSkripsi',
                        label: 'Pengajuan Skripsi',
                        active: true
                    },
                    {
                        route: 'DaftarBimbingan',
                        label: 'Daftar Bimbingan'
                    }
                ],
                date: '',
                form: {
                    data: {
                        skripsi_id: '',
                        title: '',
                        link: '',
                        file: null,
                        filePlaceholder: '',
                        mentors: []
                    },
                    loading: false
                },
                datePickerConfig: {
                    locale: 'en'
                }
            }
        },
        methods: {
            ...mapActions('skripsi', { createBimbingan: 'createBimbingan', fetch: 'fetch' }),
            fetchSkripsi () {
                this.fetch()
                    .then(res => {
                        this.form.data.skripsi_id = res.id
                        res.mentors.forEach(mentor => {
                            this.form.data.mentors.push({
                                id: mentor.id,
                                date: null,
                                time: null
                            })
                        })
                    })
            },
            submit () {
                this.form.loading = true
                this.createBimbingan(this.form.data)
                    .then(() => {
                        this.$swal({
                            imageUrl: require('@/assets/images/pengajuan-bimbingan.png'),
                            imageWidth: 400,
                            title: 'Pengajuan Bimbingan',
                            text: 'Pengajuan anda sudah berhasil, tunggu konfirmasi selanjutnnya'
                        })
                        this.$router.push({ name: 'PengajuanSkripsi' })
                        this.fetchSkripsi()
                    })
                    .catch(() => {
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something Wrong'
                        })
                    })
                    .finally(() => {
                        this.form.loading = false
                    })
            },
            onDateSelected (id, val) {
                console.log(val)
                const temp = []

                this.form.data.mentors.forEach(mentor => {
                    if (mentor.id === id) {
                        temp.push({
                            id: mentor.id,
                            date: this.formatDateStore(val),
                            time: mentor.time
                        })
                    } else {
                        temp.push(mentor)
                    }
                })

                this.form.data.mentors = temp
            },
            onTimeSelected (id, val) {
                const temp = []

                this.form.data.mentors.forEach(mentor => {
                    if (mentor.id === id) {
                        temp.push({
                            id: mentor.id,
                            date: mentor.date,
                            time: val.target.value
                        })
                    } else {
                        temp.push(mentor)
                    }
                })

                this.form.data.mentors = temp

                console.log(this.form.data.mentors)
            },
            addMentor () {
                this.form.data.mentors.push(0)
            },
            removeMentor (index) {
                this.form.data.mentors.splice(index, 1)
            }
        },
        mounted () {
            this.fetchSkripsi()
        }
    }
</script>
